<template>

	<div class="wrap p-give-sticker">
		<!-- header -->
		<header class="header-step">
			<div class="head-left"></div>
			<div class="head-center"></div>
			<div class="head-right"></div>
		</header>
		<!-- container -->
		<div class="container">
			<div class="content">
				<div class="guide-text">
					<span class="kr">칭찬 스티커 1개가 지급되었습니다!</span>
					<span class="en">You did well.</span>
				</div>
				<div class="sticker-wrap">
					<div class="sticker-box">
						<img :src="'/assets/images/learning/img_sticker_' + stickerNum + '.png'" alt="">
					</div>
					<div class="img-box">
						<img src="/assets/images/learning/img_give_sticker.png" alt="">
					</div>
				</div>
			</div>

			<audio id="sticker-sound" src="/sound/sticker/1.mp3"></audio>
		</div>
	</div>

</template>

<script>
export default {
	name: "answerComplete",
	data() {

		return {
			eaIdx: this.$route.params.eaIdx,
			userIdDec: atob(this.$route.params.userIdEnc),
			stickerNum: "01",
		}
	},
	created () {

		this.$eventBus.$emit("startProgress")
		this.doComplete();
	},
	mounted () {

	},
	methods: {

		// ecIdx 가 칭찬스티커 발급 가능한지 확인 후 발급
		doComplete () {

			const self = this
			this.axiosCaller.patch(this, this.APIs.EDU_ANSWER + "/", {
				eaIdx: this.eaIdx,
				maxCount: 3,
			}, async function (res) {

				const result = res.data
				if (result.status === "ok") {

					// 기록저장
					const recordSeq = await self.doRecording();
					if (recordSeq > 0) {

						const seq1 = recordSeq.toString().substr(recordSeq.toString().length - 1)
						if (parseInt(seq1) > 0) {
							self.stickerNum = seq1.padStart(2, '0');
						} else {
							self.stickerNum = "10";
						}
						self.$eventBus.$emit("doneProgress")

						document.querySelector('#sticker-sound').play();

						setTimeout(() => {
							self.answerClose();
						}, 2000)

					} else {

						setTimeout(() => {
							self.answerClose();
						}, 2000)
					}

				} else {
					console.log(result.msg);
				}
			})

		},




		/**
		 * 영상 기록 저장
		 */
		doRecording() {
			const self = this
			return new Promise((resolve, reject) => {

				self.axiosCaller.put(self, self.APIs.EDU_ANSWER + "/record", {
					ecIdx: this.$route.query.ecIdx,
					userId: this.getLoginId(),
					recordMode: "sticker",
					contentType: "mov",
					maxCount: 3,
				}, (res) => {
					const result = res.data
					console.log(result)
					if ( result.status === "ok" ) {
						resolve(result.recordSeq)
					} else {
						reject(result)
					}
				})
			})
		},




		// 칭찬 스티커 표출후 dpTImes 후 appClose + 부모창 새로고침
		answerClose () {

			this.appClose({
				reload: true,
			})
		}

	}
}
</script>

<style scoped>

</style>